import {Icon36CoinsStacks2Outline, Icon36Like} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {Accordion, Card, Header, Spacing, Spinner} from "@vkontakte/vkui";
import React, {useEffect, useState} from 'react';
import Countdown from "../../components/Countdown/Countdown";
import {OpenErrorSnackbar, OpenSuccessSnackbar} from "../../components/SnackBar/SnackBar";
import {checkRunningGameExistence} from "../../services/game";
import {add3DailyAttempts, getPlayerInfo} from "../../services/player";
import BonusStore from "../../store/bonus";
import GameStore from "../../store/game";
import {parseLaunchParams} from "../../utils/launch_params";
import {timeToMidnight} from "../../utils/time";
import {numberWordSuffix} from "../../utils/word";
import {Add3DailyAttemptsBonusPrice} from "../../model/prices";

// StartGame - кнопка, которая позволяет начать игру на главной странице
export function StartButton({handleButton}) {
    const routeNavigator = useRouteNavigator();
    const params = parseLaunchParams();
    const [player, setPlayer] = useState({});
    const [snackbar, setSnackbar] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasRunningGame, setHasRunningGame] = useState(false);

    useEffect(() => {
        // Получаем данные игрока
        getPlayerInfo(params.vk_user_id).then(resp => {
            const player = resp.data.response;
            setPlayer(player);
            BonusStore.setCoins(player.coins);
            GameStore.setDailyAttempts(player.daily_attempts);

            // Проверяем наличие запущенной игры у пользователя
            checkRunningGameExistence().then(resp => {
                setHasRunningGame(resp.data.response);
            })
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    // Обрабатывает нажатие на кнопку "Играть" за монеты
    const playButtonHandler = () => {
        if (handleButton) {
            handleButton();
        }

        // Чистим состояние игры
        GameStore.flush();

        // Переходим на страницу с игрой
        routeNavigator.push('/game');
    };

    // Обрабатывает нажатие на кнопку "Купить жизни" за монеты
    const addNewDailyAttemptsHandler = () => {
        if (BonusStore.coins < Add3DailyAttemptsBonusPrice) {
            setSnackbar(OpenErrorSnackbar("Не хватает монет", () => {
                setSnackbar(null);
            }));
            return;
        }

        add3DailyAttempts().then(resp => {
            const player = resp.data.response;
            setPlayer(player);
            BonusStore.setCoins(player.coins);
            GameStore.setDailyAttempts(player.daily_attempts);

            setSnackbar(OpenSuccessSnackbar("Жизни восстановлены", () => {
                setSnackbar(null);
            }));
        });
    };

    return (<>
        {snackbar}

        {isLoading && <Spinner size={"large"}/>}

        {!isLoading && <div className={"flex w-full flex-col max-w-md"}>
            <Card>
                <Header>Новая игра</Header>

                {(hasRunningGame || player.daily_attempts > 0) &&
                <>
                    <Spacing size={12}/>
                    <div className={"flex justify-center items-center flex-row gap-1"}>
                        <div
                            className={"flex justify-center items-center py-2 rounded bg-yellow-400 hover:bg-yellow-500 text-gray-800 border-solid border-2 border-yellow-400/20 w-3/4 cursor-pointer"}
                            onClick={playButtonHandler}>
                            {hasRunningGame && "Продолжить игру"}
                            {!hasRunningGame && "Начать новую игру"}
                        </div>
                    </div>

                    <Spacing size={12}/>

                    <BalancePanel player={player}/>

                    <div className={"flex flex-col"}>
                        <Accordion>
                            <Accordion.Summary iconPosition="before">Правила игры</Accordion.Summary>
                            <div className={"text-sm px-4 mb-4"}>
                                <p>Игра загадывает слово из 5-ти букв. Чтобы выиграть, вам нужно отгадать
                                    слово.</p>
                                <p className={"mt-1"}>Сразу вам доступно 5 попыток, чтобы отгадать слово, но
                                    можно
                                    приобрести дополнительную попытку за 100 монет.</p>
                                <p className={"mt-1"}>Когда в введённом слове попадётся буква, которая
                                    содержится в
                                    загаданном слове на любой позиции, такая буква будет подсвечена <span
                                        className={"border-solid rounded select-none bg-white text-gray-800"}>белым</span>.
                                </p>
                                <p className={"mt-1"}>Если буква будет стоять на той же позиции, что и в
                                    загаданном
                                    слове, то такая буква будет подсвечена <span
                                        className={"border-solid rounded select-none bg-yellow-300 text-gray-800"}>золотым</span>.
                                </p>
                                <p className={"mt-1"}>
                                    В день вы можете отгадать 3 слова = 3 жизни <Icon36Like
                                    className={"inline text-red-700 -mt-1"} height={20} width={20}/>.
                                    В полночь по МСК все жизни вновь восстанавливаются до 3-х.
                                </p>
                            </div>
                        </Accordion>
                    </div>
                </>
                }

                {(!hasRunningGame && player.daily_attempts === 0) &&
                <>
                    <Countdown date={timeToMidnight()}/>

                    <div className={"flex justify-center items-center flex-col gap-1"}>
                        <div
                            className={"flex justify-center items-center py-2 rounded text-gray-800 border-solid border-2 w-3/4  text-sm " + (player.coins < Add3DailyAttemptsBonusPrice ? "cursor-default bg-gray-300 hover:bg-gray-300 border-gray-400/20" : "cursor-pointer bg-yellow-400 hover:bg-yellow-500 border-yellow-400/20")}
                            onClick={addNewDailyAttemptsHandler}>
                            Купить 3 <Icon36Like className={"text-red-700 mx-1"} height={20} width={20}/> жизни за {Add3DailyAttemptsBonusPrice} монет
                        </div>
                    </div>

                    <BalancePanel player={player} padding={"mt-2"}/>
                </>
                }
            </Card>
        </div>}
    </>);
}

// BalancePanel отображает панель с балансом и жизнями игрока
function BalancePanel({player, padding}) {
    return <>
        <div className={"flex justify-center items-center flex-row p-2 " + padding}>
            <div className={"flex flex-row mr-2 text-gray-800"}>
                <Icon36CoinsStacks2Outline className={"text-yellow-500 -mt-1"} height={20} width={20}/>
                <span className={"ml-1 -mt-0.5 text-xs font-bold"}>{player.coins}</span>
                <span
                    className={"ml-1 -mt-0.5 text-xs"}>{numberWordSuffix(player.coins, ["монета", "монеты", "монет"])}</span>
            </div>

            <div className={"flex flex-row text-gray-800"}>
                <Icon36Like className={"text-red-700 -mt-1"} height={20} width={20}/>
                <span className={"ml-1 -mt-0.5 text-xs font-bold"}>{player.daily_attempts}</span>
                <span
                    className={"ml-1 -mt-0.5 text-xs"}>{numberWordSuffix(player.daily_attempts, ["жизнь", "жизни", "жизней"])}</span>
            </div>
        </div>
    </>;
}